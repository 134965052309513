import React, {useState} from "react";
import {useProfile} from "../../hooks/useProfile";
import style from "./ButtonOnboarding.module.scss";
import img1 from "./page1.png";
import img2 from "./page2.png";
import img3 from "./page3.png";
import {Icon} from "../../components/Icon";

export const ButtonOnboarding = () => {
  const {profile, auth, initData} = useProfile();

  const [isOnboarding, setIsOnboarding] = useState(false);

  const array = [
    {
      text_title_ru: "Голосовое планирование",
      text_description_ru:
        "Нужно быстро занести задачу в календарь? Запиши мне ее в голосовом сообщении нашего чата",
      text_title_en: "Voice scheduling",
      text_description_en:
        "Need to quickly put a task in Calendar ? Write it down for me in our chat voice message",
      img_worky: img3,
      img_width: 388,
      img_height: 487,
      img_page: "third-dot",
    },
    {
      text_title_ru: "Уведомления в Telegram",
      text_description_ru:
        "Я могу напомнить тебе до 3 раз о задаче из календаря. Ты точно ничего не забудешь!",
      text_title_en: "Notifications in Telegram",
      text_description_en:
        "I can remind you up to 3 times about a task on the calendar. You're sure not to forget anything!",
      img_worky: img1,
      img_width: 377,
      img_height: 420,
      img_page: "first-dot",
    },
    {
      text_title_ru: "Поиск через GPT-чат и Google",
      text_description_ru:
        "Я заменю тебе интернет, ассистента, учителя и даже психолога. Просто напиши мне в чат текстовое или голосовое сообщение",
      text_title_en: "GPT chat and Google search",
      text_description_en:
        "I'll replace your internet, assistant, teacher, and even your psychologist. Just send me a text or voice message in the chat room",
      img_worky: img2,
      img_width: 374,
      img_height: 403,
      img_page: "second-dot",
    }
  ];

  const [arrayInt, setArrayInt] = useState(1);

  const changeNext = () => {
    if (arrayInt < array.length) {
      setArrayInt(arrayInt+1);
    } else {
      setArrayInt(1);
      setIsOnboarding(false);
    }
  }

  return (
    <>
      {/*<Link to="/page1">*/}
      <div className={style.button} onClick={() => setIsOnboarding(true)}>
        {profile.language_code === "ru" ? "Обучение" : "Onboarding"}
      </div>
      {/*</Link>*/}

      {isOnboarding && (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: "linear-gradient(#E0F6F9, #C7E3FA)",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "flex-end",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                alignItems: "flex-end",
              }}
            >
              <img
                src={array[arrayInt - 1].img_worky}
                alt="worky"
                style={{
                  width: array[arrayInt - 1].img_width,
                  height: array[arrayInt - 1].img_height,
                  padding: 16,
                }}
              />
              {/*<Icon name={'first-page'} size={384}/>*/}
            </div>
            <div
              style={{
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              <div>
                <h2
                  style={{
                    fontSize: 24,
                  }}
                >
                  {profile.language_code === "ru"
                    ? array[arrayInt - 1].text_title_ru
                    : array[arrayInt - 1].text_title_en}
                </h2>
                <div
                  style={{ fontSize: 16, paddingLeft: 32, paddingRight: 32 }}
                >
                  {profile.language_code === "ru"
                    ? array[arrayInt - 1].text_description_ru
                    : array[arrayInt - 1].text_description_en}
                </div>
              </div>
              {/*<Icon name={array[arrayInt-1].img_page} size={82}/>*/}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#f1f1f1",
                    borderRadius: 48,
                    padding: "4px 8px",
                    height: 24,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {array.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        height: 6,
                        width: 6,
                        borderRadius: "50%",
                        display: "inline-block",
                        margin: "2px",
                        backgroundColor:
                          arrayInt - 1 === index ? "#202020" : "#dbdbdb",
                      }}
                    ></div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  borderTop: "2px solid #ECECEC",
                  padding: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    border: 11,
                    background:
                      "linear-gradient(to right, #FF713C, #F5562A, #FAEADC)",
                    color: "white",
                    //text-align: center;
                    //vertical-align: middle;
                    borderRadius: 4,
                    height: 45,
                  }}
                  onClick={changeNext}
                >
                  {profile.language_code === "ru" ? "Далее" : "Next"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
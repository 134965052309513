import React from "react";
import {useProfile} from "../../hooks/useProfile";
import {Link} from "react-router";
import {Page} from "../../components/Icon/icons/page1.svg"
import {Icon} from "../../components/Icon";
import style from "./Onboarding.module.scss"

export const FirstPage = () => {
  const {profile, auth, initData} = useProfile();
  return (
      <>
        <Icon name="first-page"/>
        <div className={style.container}>
          <div>
            <h2>Notifications in Telegram</h2>
          </div>
          <div>
            I can remind you up to 3 times about a task on the calendar. You're sure not to forget anything!
          </div>
          <div>
            <Icon name="first-dot" size={82}></Icon>
          </div>
        </div>
        <div className={style.footer}>
          <Link to="/page2">
            <div className={style.footer__button}>
              Next
            </div>
          </Link>
        </div>
      </>
  );
}
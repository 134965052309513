import React from "react";
import {useProfile} from "../../hooks/useProfile";
import {Link} from "react-router";
import {Icon} from "../../components/Icon";
import style from "./Onboarding.module.scss"
import image from "../ButtonOnboarding/page1.png"

export const SecondPage = () => {
  const {profile, auth, initData} = useProfile();
  return (

      <>
      <img src={image} alt="Worky can search information in Internet and also in ChatGPT" />
        <div className={style.container}>
          <div>
            <h2>{profile.language_code === 'ru' ? "Chat GPT и поиск в интернете" : "GPT chat and Google search"}</h2>
          </div>
          <div>
            {profile.language_code === 'ru' ? "Я заменю тебе интернет, ассистента, учителя и даже психолога. Просто отправь мне текстовое или голосовое сообщение в чат" :
                "I'll replace your internet, assistant, teacher, and even your psychologist. Just send me a text or voice message in the chat room"}
          </div>
          <div>
            <Icon name="second-dot" size={82}></Icon>
          </div>
        </div>
        <div className={style.footer}>
          <div className={style.footer__button}>Next</div>
        </div>
      </>
  );
}
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import moment from "moment";
import {
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from "@mui/material";
import {Link} from "react-router";
import {useProfile} from "../../hooks/useProfile";
import {Icon} from "../../components/Icon";
import style from "../CalendarTask/CalendarTask.module.scss"; // используем стиль из другой директории, возможнло нужно его вынести в общие стили. или как то по другому оформить
import {CustomCalendar} from "../CalendarTask/CalendarTask";
import {ROOT_URL} from "../../config/url";

export const CalendarHabits = () => {
  const {profile, auth, initData} = useProfile();
  const [habitsCount, setHabitsCounts] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [habits, setHabits] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const currentDateRef = useRef(moment());

  const [selectedHabit, setSelectedHabit] = useState(null);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleTaskClick = (task) => {
    setSelectedHabit(task);
    setActionMenuOpen(true);
  };

  const handleCloseActionMenu = () => {
    setActionMenuOpen(false);
    setSelectedHabit(null);
  };

  useEffect(() => {
    axios.get('/api/v1/habits/month', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        year: selectedDate.format("YYYY"), month: selectedDate.format("MM")
      }
    }).then((response) => {
      if (!!response.data.data.habits_by_day) {
        const counts = response.data.data.habits_by_day.reduce((acc, {day, habit_count}) => {
          acc[day] = habit_count;
          return acc;
        }, {});
        setHabitsCounts({...counts});
      } else {
        setHabitsCounts({});
      }
    });
  }, [isUpdate, selectedDate]);

  useEffect(() => {
    axios.get(
      '/api/v1/habits/day',
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          date: selectedDate.format("YYYY-MM-DD")
        }
      }).then((response) => {
      setHabits(response.data.data);
    });
  }, [isUpdate, selectedDate]);

  return (<>
    <div className={style.calendarContainer}>
      <div className={style.calendarMenu}>
        <div className={style.calendarMenu__cursor}>
          <Link to={"/dashboard"}>
            <Icon
              name={"icon-round-arrow-left"}
              size={24}
              color={"#A4A4A4"}
            />
          </Link>
        </div>
        <span className={style.calendarMenu__text}>
              {profile.language_code === "ru" ? "Привычки дня" : "Habits of the day"}
            </span>
        <div className={style.calendarMenu__cursor}>
          <Icon name={"icon-add-circle"} size={24} color={"#FF7244"}/>
        </div>
      </div>
      <CustomCalendar
        selectedDate={selectedDate}
        onDateChange={handleDateChange}
        taskCounts={habitsCount}
        languageCode={profile.language_code}
      />
      <div
        style={{
          border: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "2px solid #FF713C" : "2px solid #c4c4c4",
          color: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "#FF713C" : "#202020",
        }}
        className={style.calendarDateCurrent}
      >
        <div
          style={{
            display: "flex", justifyContent: "center",
          }}
        >
              <span style={{marginRight: 4}}>
                {profile.language_code === "ru" ? currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Сегодня, " : "" : currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Today is " : ""}
              </span>
          {selectedDate.format("DD.MM.YYYY")}
        </div>
      </div>

      <div>
        {!!habits && habits.map(habit => (<div key={habit.uuid}
                                              className={style.task}
        >
          <Checkbox
            checked={!!habit.checked_at} // TODO
            color={"success"}
            className={style.task__checkbox}
            // onClick={() => handleCheckboxChange(habit.uuid)} // TODO
          />
          <div style={{
            paddingTop: 8
          }}
               onClick={() => handleTaskClick(habit)}>
            {!!habit.time && (<span style={{
              marginRight: 4, fontWeight: 700, color: "#7447FF"
            }}>{habit.time}</span>)}{habit.title}

          </div>
        </div>))}
      </div>
    </div>
  </>);
}

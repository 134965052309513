import React, {useEffect, useState} from "react";
import {Checkbox, Divider} from "@mui/material";
import {ROOT_URL} from "../../../../config/url";
import moment from "moment";
import {useProfile} from "../../../../hooks/useProfile";
import axios from "axios";
import {TaskAddModal} from "./components/TaskAddModal";
import {TaskEditModal} from "./components/TaskEditModal";
import {Link} from "react-router";
import {Icon} from "../../../../components/Icon";

export const useTaskList = () => {
  const {profile, auth, initData} = useProfile();

  const [tasks, setTasks] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(null);

  useEffect(() => {
    axios.get(
      '/api/v1/tasks/day',
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          date: moment().format("YYYY-MM-DD")
        }
      }).then((response) => {
      setTasks(response.data.data);
    });
  }, [isUpdate]);

  const handleCheckboxChange = (taskUuid) => {
    axios.put(
      '/api/v1/task/' + taskUuid + '/checked', {},
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          date: moment().format("YYYY-MM-DD")
        }
      }).then((response) => {
      setTasks((prevTasks) =>
        [...prevTasks.map((task) => {
          if (task.uuid === taskUuid) {
            task.checked_at = response.data.data.checked_at;
          }
          return task;
        })]
      );
    });
  };

  // TODO Сюда бы еще сортировку по времени сделать.
  const editTask = (object) => {
    setTasks((prevTasks) =>
      [...prevTasks.map((task) => {
        if (task.uuid === object.uuid) {
          task = object;
        }
        return task;
      })]
    );
  }

  return {
    profile,

    tasks,

    isModalAdd,
    setIsModalAdd,

    modalEdit,
    setModalEdit,

    isUpdate,
    setIsUpdate,

    handleCheckboxChange,
  }
}

import React from "react";
import {TaskList} from "./components/TaskList";
import {HabitList} from "./components/HabitList/HabitList";
import {ButtonOnboarding} from "../ButtonOnboarding/ButtonOnboarding";
import style from "./Dashboard.module.scss";
import {useProfile} from "../../hooks/useProfile";
import money from './components/money-worky.png';
import glass from './components/money-glass.png';
import worky from './components/newyear.png';
import {Link} from "react-router";

export const Dashboard = () => {
  const { profile, auth, initData } = useProfile();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          alignItems: "stretch",
          height: 260,
        }}
      >
        <TaskList />

        {/*<Productivity/>*/}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          alignItems: "stretch",
          height: 260,
          marginTop: 15,
        }}
      >
        <HabitList/>

        {/*<Productivity/>*/}
      </div>

      <div>
        <Link to={"/calendar-task"} style={{ textDecoration: "none" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              border: 11,
              marginTop: 16,
              background:
                "linear-gradient(to right, #FF713C, #F5562A, #FAEADC)",
              color: "white",
              //text-align: center;
              //vertical-align: middle;
              borderRadius: 4,
              height: 45,
            }}
          >
            {profile.language_code === "ru" ? "Календарь" : "Calendar"}
          </div>
        </Link>
      </div>
      <div>
        <ButtonOnboarding />
      </div>
      {/*todo profile.language_code работает тут некорректно, не как на всех страницах, почему?*/}
      <div>
        <Link to="" style={{ textDecoration: "none" }}>
          <div className={style.info} style={{ marginTop: 32 }}>
            <span
              style={{
                borderLeft: "50px",
              }}
            >
              {profile.language_code === "ru"
                ? "Заработать с Worky"
                : "Earn money with Worky"}
            </span>
            <img src={money} alt="1" style={{ width: 44 }} />
          </div>
        </Link>
        <div className={style.info} style={{ marginTop: 11 }}>
          <span
            style={{
              borderLeft: "50px",
            }}
          >
            {profile.language_code === "ru"
              ? "Подписка с Worky"
              : "Subscribe on Worky"}
          </span>
        </div>
        <Link to="" style={{ textDecoration: "none" }}>
          <div className={style.info} style={{ marginTop: 11 }}>
            <img src={glass} alt="1" style={{ width: 44 }} />
            <span
              style={{
                borderRight: "150px",
              }}
            >
              Worky Help
            </span>
          </div>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={worky} alt="" style={{ width: 236 }} />
        <span
          style={{
            color: "#1982F4",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          {profile.language_code === "ru"
            ? "Будь лучше с Worky"
            : "Be your best with Worky"}
        </span>
      </div>
    </>
  );
}

import React, {useRef, useState} from 'react';
import {useProfile} from "../../hooks/useProfile";
import {Icon} from "../../components/Icon"
import style from "./ProfileForm.module.scss";
import {Link} from "react-router";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import axios from "axios";
import {ROOT_URL} from "../../config/url";
import {profileInit} from "../../contexts/profile";
import moment from 'moment-timezone';

// форматируем дату в зависимости от языка пользователя
function formatDate(dateString, languageCode) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // месяцы начинаются с 0
  const year = date.getFullYear();

  if (languageCode === "ru") { // европейский формат
    return `${day}.${month}.${year}`
  } else { // американский формат
    return `${month}.${day}.${year}`
  }
}


export const ProfileForm = () => {
  const {profile, user, auth, initData, profileDispatch} = useProfile();

  const languageCodeRef = useRef(null);
  const timezoneRef = useRef(null);

  const [languageCode, setLanguageCode] = useState(profile.language_code || '');
  const [timezone, setTimezone] = useState(profile.utc || '');

  // Функция для получения уникальных тайм-зон в формате UTC±X
  function getUniqueSortedTimezones() {
    const timezones = moment.tz.names();
    const uniqueTimezones = timezones.map((tz) => {
      const offset = moment.tz(tz).utcOffset(); // Получаем смещение в минутах
      const sign = offset >= 0 ? '+' : '-';
      const absoluteOffset = Math.abs(offset);

      // Получаем часы и минуты
      const hours = Math.floor(absoluteOffset / 60);
      const minutes = absoluteOffset % 60;

      // Форматируем в формате UTC±X:YY
      const formattedOffset = `UTC${sign}${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
      return { name: tz, offset: formattedOffset, offsetValue: offset / 60 }; // может оставить в часах, если нужно
    });

    // Убираем повторения по смещению и сортируем
    const uniqueSorted = Array.from(
      new Map(uniqueTimezones.map((tz) => [tz.offset, tz])).values()
    ).sort((a, b) => a.offsetValue - b.offsetValue);

    return uniqueSorted;
  }

  // Получаем список тайм-зон
  const timezones = getUniqueSortedTimezones();

  // Функция для получения всех тайм-зон в формате UTC±X
  // function getTimezonesInUTCFormat() {
  //   const timezones = moment.tz.names();
  //   return timezones.map((tz) => {
  //     const offset = moment.tz(tz).utcOffset() / 60; // Получаем смещение в часах
  //     const sign = offset >= 0 ? '+' : '-';
  //     const formattedOffset = `UTC${sign}${Math.abs(offset)}`;
  //     return { name: tz, offset: formattedOffset };
  //   });
  // }

  // const timezones = moment.tz.names();

  // Генерируем список тайм-зон в формате UTC±X
  // const timezones = getTimezonesInUTCFormat();

  // function getLocalizedTimezone(tz) {
  //   const zone = timezoneData.find((zone) => zone.name === tz);
  //   return profile.language_code === "ru" ? zone?.name_ru || tz : tz; // Вернёт русский перевод или оригинал
  // }

  // function getLocalizedTimezone(tz) {
  //   const zone = timezones.find((zone) => zone.name === tz);
  //   // return profile.language_code === 'ru'
  //   //   ? `${zone.offset} (${zone.name})`
  //   //   : zone.offset;
  //   return zone.offset;
  // }

  // function getLocalizedTimezone(tz) {
  //   return profile.language_code === 'ru'
  //     ? `${tz.offset} (${tz.name})`
  //     : tz.offset;
  // }

  // console.log("profile:", profile, "user:", user);

  const changeLanguageCode = (code) => {
    const body = JSON.stringify({
      language_code: code,
    });

    axios.put(
      '/api/v1/profile/edit/language', body,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        }
      }).then(() => {
      setLanguageCode(code);
      void profileInit(profileDispatch, ROOT_URL, user, auth, initData, null).then();
    });
  };

  const changeTimezone = (timezone) => {
    const body = JSON.stringify({
      utc: timezone,
    });

    axios.put(
      '/api/v1/profile/edit/timezone', body,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        }
      }).then(() => {
      setTimezone(timezone);
      void profileInit(profileDispatch, ROOT_URL, user, auth, initData, null).then();
    });
  };

  return (
    // <header className={style.block}>
    //   <div className={style.block__title}>
    //     <Icon name={'logo-worky'} size={48}/>
    //   </div>
    //   <div className={style.block__menu}>
    //     <span className={style.profile}>
    //       {!!profile && !!profile.first_name && profile.first_name}
    //       {!!profile && !profile.first_name && !!profile.telegram_username && profile.telegram_username}
    //     </span>
    //     <a>
    //       <Icon name={'icon-user'} size={24}/>
    //     </a>
    //   </div>
    // </header>
    <>
      <div className={style.info_container}>
        <div className={style.info_back}>
          <Link to={"/dashboard"} style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            fontSize: 14,
            textDecoration: "none",
            color: "#A4A4A4"
          }}>
            <Icon name={'icon-round-arrow-left'} size={24} color={'#A4A4A4'}/>
            {profile.language_code === 'ru' ? "Назад" : "Back"}
          </Link>
        </div>
        <div className={style.info_data}>
          <div style={{
            margin: 10,
            borderRadius: 50
          }}>

            <img src={user.photo_url} alt="Profile Image" style={{borderRadius: "50%", width: 58, height: 58}}/>
          </div>

          <span className={style.info_data__nickname}>
              {/*Вань, помоги мне разобраться с этими выражениями, в целом с профилем*/}
            {/*todo, если ник, то добавляем собаку, если имя, то собаку убираем*/}
            {!!profile && !!profile.first_name && profile.first_name}
            {!!profile && !profile.first_name && !!profile.telegram_username && "@" + profile.telegram_username}
            </span>
          <span className={style.info_data__subtitle} style={{margin: 10}}>
              {profile.language_code === 'ru' ? `Ваше путешествие с Worky начинается с ${formatDate(profile.created_at, profile.language_code)}`
                : `Your journey with Worky begins on ${formatDate(profile.created_at, profile.language_code)}`}
            </span>
        </div>
      </div>
      <div className={style.buttons}>
        <FormControl style={{
          marginBottom: 16
        }}>
          <InputLabel>{profile.language_code === 'ru' ? `Язык` : `Language`}</InputLabel>
          <Select
            label={profile.language_code === 'ru' ? "Язык" : "Language"}
            value={languageCode}
            onChange={(e) => changeLanguageCode(e.target.value)}
            inputRef={languageCodeRef}
          >
            <MenuItem value="ru">{profile.language_code === 'ru' ? "Русский" : "Russian"}</MenuItem>
            <MenuItem value="en">{profile.language_code === 'ru' ? "Английский" : "English"}</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>{profile.language_code === 'ru' ? `Часовой пояс` : `Time zone`}</InputLabel>
          {/*<Select*/}
          {/*  label={profile.language_code === 'ru' ? "Часовой пояс" : "Time zone"}*/}
          {/*  value={timezone}*/}
          {/*  onChange={(e) => changeTimezone(e.target.value)}*/}
          {/*  inputRef={timezoneRef}*/}
          {/*>*/}
          {/*  {timezones.map((tz) => (*/}
          {/*    <MenuItem key={tz} value={tz}>*/}
          {/*      {getLocalizedTimezone(tz)}*/}
          {/*    </MenuItem>*/}
          {/*  ))}*/}
          {/*</Select>*/}
          <Select
            label={profile.language_code === 'ru' ? "Часовой пояс" : "Time zone"}
            value={timezone}
            onChange={(e) => changeTimezone(e.target.value)}
            inputRef={timezoneRef}
          >
            {/*{timezones.map((tz) => (*/}
            {/*  <MenuItem key={tz.name} value={tz.name}>*/}
            {/*    {getLocalizedTimezone(tz.name)}*/}
            {/*  </MenuItem>*/}
            {/*))}*/}
            {/*{timezones.map((tz) => (*/}
            {/*  <MenuItem key={tz.name} value={tz.name}>*/}
            {/*    {tz.offset}*/}
            {/*  </MenuItem>*/}
            {/*))}*/}
            {/* Берём оффсет для списка UTC и все */}
            {timezones.map((tz) => (
              <MenuItem key={tz.offset} value={tz.offset}>
                {tz.offset}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}

export default ProfileForm;

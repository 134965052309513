import React, {useEffect, useRef, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from "@mui/material";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import moment from "moment/moment";
import {useProfile} from "../../../../../hooks/useProfile";
import {Icon} from "../../../../../components/Icon";

export const TaskEditModal = ({open, handleClose, object, successfulResponse}) => {
  const {profile, auth, initData} = useProfile();

  const [task, setTask] = useState(null);

  const titleRef = useRef(null);
  const dateRef = useRef(null);
  const timeRef = useRef(null);
  const priorityRef = useRef(null);

  const [title, setTitle] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [priority, setPriority] = useState("NO_PRIORITY");
  const [reminderEnabled, setReminderEnabled] = useState(false);
  const [reminder, setReminder] = useState(15); // Default to 15 minutes
  const [time, setTime] = useState("");

  useEffect(() => {
    if (!!object) {
      axios.get(
        '/api/v1/task/' + object.uuid,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            'X-Telegram-Auth-Date': auth.auth_date,
            'X-Telegram-Hash': auth.hash,
            'X-Telegram-Init-Data': btoa(initData),
            'User': profile.uuid,
            'Content-Type': 'application/json'
          },
          params: {
            date: moment().format("YYYY-MM-DD")
          }
        }).then((response) => {
        setTask((!!response.data.data && response.data.data) || null);

      }).catch(() => {
        handleClose();
      });
    }
  }, [object]);

  useEffect(() => {
    setTitle((!!task && !!task.title && task.title) || "");
    setDate((!!task && !!task.date && task.date) || "");
    setTime((!!task && !!task.time && task.time) || "");
    setReminderEnabled(!!task && task.is_remind);
    setReminder((!!task && !!task.remind_for_time && task.remind_for_time) || 15);
  }, [task]);

  const handleSubmit = () => {
    const taskData = JSON.stringify({
      title: titleRef.current?.value,
      date: dateRef.current?.value,
      time: (time.length > 0 && time) || null,
      priority_type: priority,
      is_remind: reminderEnabled,
      remind_for_time: reminderEnabled ? reminder : null,
    });

    axios.put(
      '/api/v1/task/' + task.uuid + '/edit', taskData,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
      successfulResponse(response.data.data);
      handleClose();
    });
  };

  const handleTimeClear = () => setTime("");

  return (!!task &&
    <Dialog open={open} onClose={handleClose} style={{
      borderRadius: 21
    }}>
      <DialogTitle style={{
        fontSize: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 16px 0"
      }}>
        <div style={{
          height: 24,
        }}>
        
          {profile.language_code === 'ru' ? `Редактировать задачу` : `Edit task`}
        </div>
        <div style={{
          height: 24,
          cursor: "pointer"
        }}
             onClick={handleClose}>
          <Icon name={'icon-close-circle'} size={24} color={"#FF7244"}/>
        </div>
      </DialogTitle>
      <DialogContent style={{
        width: 320,
        padding: "0 16px 16px"
      }}>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            inputRef={titleRef}
            label={profile.language_code === 'ru' ? "Задача" : "Task"}
       
            variant="outlined"
            placeholder={profile.language_code === 'ru' ? "Сходить в ..." : "Go to the..."}
              style={{
                width: "calc(100% - 10px)"
              }}
          />
          <TextField
            value={date}
            onChange={(e) => setDate(e.target.value)}
            inputRef={dateRef}
            label={profile.language_code === 'ru' ? "Дата" : "Date"}
            type="date"
            InputLabelProps={{shrink: true}}
              style={{
                width: "calc(100% - 10px)"
              }}
          />
          <Box display="flex" alignItems="center" gap={1} style={{
            justifyContent: "space-between",
               width: "calc(100% - 10px)"
          }}>
            <TextField
              value={time}
              onChange={(e) => setTime(e.target.value)}
              label={profile.language_code === 'ru' ? "Время" : "Time"}
              type="time"
              placeholder={"--:--"}
              style={{
                width: 120
              }}
              InputLabelProps={{shrink: true}}
            />
            {time !== "" && (
              <Button onClick={handleTimeClear} variant="outlined" color="secondary" style={{
                borderRadius: 24,
                fontSize: 12
              }}>
                {profile.language_code === 'ru' ? "Удалить время" : "No time limit"}
              </Button>
            )}
          </Box>
          <FormControl>
            <InputLabel>{profile.language_code === 'ru' ? `Приоритет` : `Priority`}</InputLabel>
            <Select
                 label={profile.language_code === 'ru' ? "Приоритет" : "Priority"}
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              inputRef={priorityRef}
                style={{
                width: "calc(100% - 10px)"
              }}
            >
              <MenuItem value="NO_PRIORITY">{profile.language_code === 'ru' ? "Не срочно" : "Not urgent"}</MenuItem>
              <MenuItem value="PRIORITY">{profile.language_code === 'ru' ? "Срочно" : "Urgent"}</MenuItem>
              <MenuItem value="VERY_PRIORITY">{profile.language_code === 'ru' ? "Очень срочно" : "Very urgent"}</MenuItem>
       </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={reminderEnabled}
                color={'success'}
                onChange={(e) => setReminderEnabled(e.target.checked)}
              />
            }
            label={profile.language_code === 'ru' ? "Нужно напоминание" : "Need a reminder"}
                style={{
                width: "calc(100% - 10px)"
              }}
          />
          {reminderEnabled && (
            <FormControl>
              <InputLabel>{profile.language_code === 'ru' ? `Напоминание` : `Reminder`}</InputLabel>
              <Select
                 label={profile.language_code === 'ru' ? "Напоминание" : "Reminder"}
                value={reminder}
                onChange={(e) => setReminder(Number(e.target.value))}
                  style={{
                width: "calc(100% - 10px)"
              }}
              >
              <MenuItem value={15}>{profile.language_code === 'ru' ? `15 минут` : `in 15 minutes`}</MenuItem>
                <MenuItem value={30}>{profile.language_code === 'ru' ? `30 минут` : `in 30 minutes`}</MenuItem>
                <MenuItem value={60}>{profile.language_code === 'ru' ? `60 минут` : `in 60 minutes`}</MenuItem>
                 </Select>
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions style={{
        padding: "0 16px 16px"
      }}>
        <Button onClick={handleSubmit} variant="contained" color="primary" style={{
          width: "100%",
          color: "white",
          fontSize: 14,
          textTransform: "none",
        }}>
          
          {profile.language_code === 'ru' ? "Изменить задачу" : "Edit a task"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

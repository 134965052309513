import axios from 'axios'

function getUTCOffset() {
  const offsetMinutes = new Date().getTimezoneOffset(); // смещение в минутах
  const offsetHours = -offsetMinutes / 60; // преобразуем в часы
  const sign = offsetHours >= 0 ? '+' : '-';
  const formattedOffset = `UTC${sign}${Math.abs(offsetHours)}`;
  return formattedOffset;
}

export const profileInit = async (dispatch, rootUrl, user, auth, initData, callback) => {
  dispatch({type: "PROFILE_REQUEST"});

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const timezone = getUTCOffset();

  const headers = {
    'X-Telegram-Auth-Date': auth.auth_date,
    'X-Telegram-Hash': auth.hash,
    'X-Telegram-Init-Data': btoa(initData),
    'Timezone': timezone
  };

  try {
    const response = await axios.get(
      '/api/v1/telegram/profile',
      {
        withCredentials: false,
        baseURL: rootUrl,
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        params: {
          telegram_id: user.id
        }
      });

    const profile = response.data.data;
    // console.log(profile);

    dispatch({type: "PROFILE_SUCCESS", profileData: profile, telegramAuth: auth, telegramInitData: initData, telegramUser: user});

    if (callback) {
      callback();
    }
  } catch (error) {
    if (error.status === 404) {
      try {

        const jsonData = JSON.stringify({
          telegram_id: String(user.id),
          telegram_username: user.username,
          first_name: user.first_name,
          last_name: user.last_name,
          language_code: user.language_code,
          timezone: timezone,
        });

        const response = await axios.post(
          '/api/v1/telegram/register', jsonData,
          {
            withCredentials: false,
            baseURL: rootUrl,
            headers: {
              ...headers,
              'Content-Type': 'application/json'
            },
            params: {
              telegram_id: user.id,
              telegram_username: user.username,
              first_name: user.first_name,
              last_name: user.last_name,
              language_code: user.language_code,
            }
          });

        const profile = response.data.data;
        // console.log(profile);

        dispatch({type: "PROFILE_SUCCESS", profileData: profile, telegramAuth: auth, telegramInitData: initData, telegramUser: user});

        if (callback) {
          callback();
        }
      } catch (error) {
        dispatch({type: "PROFILE_ERROR", profileError: 'Что то пошло не так, попробуйте обновить страницу'});
      }
    } else {
      dispatch({type: "PROFILE_ERROR", profileError: 'Что то пошло не так, попробуйте обновить страницу'});
    }
  }
}

export const profileLogout = (dispatch) => {
  dispatch({type: "PROFILE_LOGOUT"});
}

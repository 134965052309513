import React from "react";
import {useProfile} from "../../hooks/useProfile";
import {Link} from "react-router";
import {Icon} from "../../components/Icon";
import style from "./EarnMoney.module.scss";


export const EarnMoney = () => {
  const {profile, auth, initData} = useProfile();
  return (
      <>
        <div className={style.info_container}>
          <div className={style.info_back}>
            <Icon name={'icon-round-arrow-left'} size={24} color={'#A4A4A4'}/>
            Назад
          </div>
        </div>
          </>
          );
          }

          export default EarnMoney;
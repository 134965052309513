export const initialState = {
  isProfileInit: false,
  profileRequest: false,
  profileError: null,
  profileData: null,
  telegramAuth: null,
  telegramInitData: null,
  telegramUser: null
}

export const profileReducer = (initialState, action) => {
  switch (action.type) {
    case "PROFILE_REQUEST":
      return {
        ...initialState, profileRequest: true, profileError: null
      }
    case "PROFILE_ERROR":
      return {
        ...initialState, isProfileInit: true, profileRequest: false, profileError: action.profileError, profileData: null, telegramAuth: null, telegramInitData: null, telegramUser: null
      }
    case "PROFILE_SUCCESS":
      return {
        ...initialState, isProfileInit: true, profileRequest: false, profileError: null, profileData: action.profileData, telegramAuth: action.telegramAuth, telegramInitData: action.telegramInitData, telegramUser: action.telegramUser
      }
    case "PROFILE_LOGOUT":
      return {
        ...initialState, profileError: null, profileData: null, telegramAuth: null, telegramInitData: null, telegramUser: null
      }
    default:
      return initialState
  }
}

import React, {createContext, useEffect, useReducer} from 'react'
import {initialState, profileReducer} from './profileReducer'
import {profileInit, profileLogout} from './profileAction'
import {useTelegram} from "../../hooks/useTelegram";

export const ProfileContext = createContext({profileState: initialState});

export const ProfileProvider = (props) => {
  const {onClose, tg, user, auth, initData} = useTelegram();
  const [profileState, profileDispatch] = useReducer(profileReducer, initialState);

  useEffect(() => {
    if (!!user && !!auth) {
      void profileInit(profileDispatch, props.rootUrl, user, auth, initData, null).then();
    } else {
      profileLogout(profileDispatch);
    }
  }, [user, auth]);

  return (
    <ProfileContext.Provider value={{profileState, profileDispatch}}>
      {props.children}
    </ProfileContext.Provider>
  )
}

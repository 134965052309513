import React from "react";
import {useProfile} from "../../hooks/useProfile";
import {Link} from "react-router";
import {Page} from "../../components/Icon/icons/page1.svg"
import {Icon} from "../../components/Icon";
//import style from "./Onboarding.module.scss";
import glass from "../Dashborad/components/money-glass.png";
import phone from "./phone-worky.png";


export const WorkyHelp = () => {
  const {profile, auth, initData} = useProfile();
  return (
      <>
        <Link to="/dashboard" style={{textDecoration: "none"}}>
          <div style={{
            height: 44,
            width: "100%",
            backgroundColor: "white",
            borderRadius: 12,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: "2px solid #E3E3E3",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 10,
          }}>
            <div style={{marginLeft: 10, display: 'flex', alignItems: "center"}}>
              <Icon name={'icon-round-arrow-left'} size={24} color={'#A4A4A4'}/>
            </div>

            <div style={{color: "#A4A4A4"}}>
              {profile.language_code === 'ru' ? 'Назад' : 'Back'}
            </div>

          </div>
        </Link>
        <div style={{
          backgroundColor: "white",
          width: "100%",

          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          color: "#1982F4",
          textAlign: "center",
        }}>
          <h2>Worky Help</h2>
          <div style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            fontWeight: "bold",

          }}>
            <div style={{backgroundColor: "#F1FBFC", padding: 5}}>
              <div>{profile.language_code === 'ru' ? `Как работать с Worky` : 'How to start work with Worky?'}</div>
              <img src={glass} alt="" style={{width: 103}}/>
            </div>
            <div style={{
              backgroundColor: "#F1FBFC",
              padding: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "end"
            }}>
              {profile.language_code === 'ru' ? 'Как работать с Worky' : 'How to start work\n with Worky?'}
              <img src={phone} alt="" style={{width: 103}}/>
            </div>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            fontWeight: "bold",
            marginTop: 13,
          }}>
            <div style={{backgroundColor: "#F1FBFC", padding: 5}}>
              <div>{profile.language_code === 'ru' ? `Как купить Stars для оплаты Worky?` : 'How to start work with Worky?'}</div>
              <img src={glass} alt="" style={{width: 103}}/>
            </div>
            <div style={{backgroundColor: "#F1FBFC", padding: 5}}>
              <div>{profile.language_code === 'ru' ? `Как работать с Worky` : 'How to start work with Worky?'}</div>
              <img src={glass} alt="" style={{width: 103}}/>
            </div>
          </div>
        </div>
      </>
  );
}

export default WorkyHelp;
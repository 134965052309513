import React from 'react';
import {useProfile} from "../../hooks/useProfile";
import {Icon} from "../Icon";
import style from './Header.module.scss';
import {Link} from "react-router";

function Header() {
  const {profile} = useProfile();

  return (
    <header className={style.block}>
      <div className={style.block__title}>
        <Icon name={'logo-worky'} size={48}/>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className={style.block__menu}>
          <Link to={'/profile-form'} style={{textDecoration: "none", display: "flex", alignItems: "center", marginRight: 8}}>
            {/*<span className={style.profile}>*/}
            {/*  {!!profile && !!profile.first_name && profile.first_name}*/}
            {/*  {!!profile && !profile.first_name && !!profile.telegram_username && profile.telegram_username}*/}
            {/*</span>*/}
            <Icon name={'user-logo'} size={40}/>
          </Link>
          <Link to={''} style={{textDecoration: "none"}}>
            <div style={{height: 40}}>
              <Icon name={'telegram-icon'} size={40} color={'#D9EFF2'}/>
            </div>
          </Link>
        </div>
      </div>
      {/*<Link to={'https://t.me/+rXkqH22YJtxiYzIy'} style={{textDecoration: "none"}}>*/}
      {/*  <div>*/}
      {/*    <Icon name={'telegram-icon'} size={40} color={'#D9EFF2'} />*/}
      {/*  </div>*/}
      {/*</Link>*/}
    </header>
  );
}

export default Header;

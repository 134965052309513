import {Routing} from "./Routing";
import {useProfile} from "../hooks/useProfile";

export const Screen = () => {
  const {profile} = useProfile();

  return (
    <>
      {!profile && (
        <>
          ERROR
        </>
      )}

      {profile && <Routing />}
    </>
  );
};

import {useEffect, useState} from "react";

const tg = window.Telegram.WebApp;

export function useTelegram() {
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(null);
  const [initData, setInitData] = useState(null);

  useEffect(() => {
    if (process.env.REACT_APP_DEV_TG === 'true') {
      setUser({
        allows_write_to_pm: true,
        first_name: "Ivan",
        id: 999999999,
        is_premium: true,
        language_code: "ru",
        last_name: "Ivanov",
        photo_url: "https://t.me/i/userpic/320/.svg",
        username: "nickname",
      });

      setAuth({
        auth_date: "1733922865",
        hash: "39c4f9ab88e50b96c9f23242e1c849ed798a669696fa16dac1ba8d224f7641fc",
        query_id: "AAEnDACcM6SAczIdX",
        signature: "WTZSYbiMd1wvSwik6fgi8LwvZ45sLp7tPelC350h39H8ql9unw_61j_MTyR1XHfuomh8vjGxAwfDjk46D",
        user: {
          allows_write_to_pm: true,
          first_name: "Ivan",
          id: 999999999,
          is_premium: true,
          language_code: "ru",
          last_name: "Ivanov",
          photo_url: "https://t.me/i/userpic/320/.svg",
          username: "nickname",
        },
      });

      setInitData(
        "query_id=AAEnDOkgAAAAACcM6SBQPQD2&user=%7B%22id%22%3A552143911%2C%22first_name%22%3A%22Vanya%22%2C%22last_name%22" +
        "%3A%22%22%2C%22username%22%3A%22vokymlak%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allow" +
        "s_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FC2vsRD2" +
        "meHlHlpypvLDO7VZIBnbzXpxOIPytRoIJq18.svg%22%7D&auth_date=1733922865&signature=WTZSYbiMd1wvSwik6fgi8LwvZ45sLp7tPel" +
        "C350h39H8ql9unw_61j_MTyR1XHfuomh8vjGxAwfDjk46D-R6Bg&hash=39c4f9ab88e50b96c9f23242e1c849ed798a669696fa16dac1ba8d224f7641fc"
      );
    } else {
      setUser(!!tg && !!tg.initDataUnsafe && !!tg.initDataUnsafe.user && {
        allows_write_to_pm: tg.initDataUnsafe.user.allows_write_to_pm, // (boolean).
        first_name: tg.initDataUnsafe.user.first_name, // Для регистрации (string).
        id: tg.initDataUnsafe.user.id, // Для регистрации (number).
        is_premium: tg.initDataUnsafe.user.is_premium, // (boolean).
        language_code: tg.initDataUnsafe.user.language_code, // Для регистрации (string).
        last_name: tg.initDataUnsafe.user.last_name, // Для регистрации (string).
        photo_url: tg.initDataUnsafe.user.photo_url,
        username: tg.initDataUnsafe.user.username, // Для регистрации (string).
      } || null);

      setAuth(!!tg && !!tg.initDataUnsafe && {
        auth_date: tg.initDataUnsafe.auth_date,
        hash: tg.initDataUnsafe.hash,
        query_id: tg.initDataUnsafe.query_id,
        signature: tg.initDataUnsafe.signature,
      } || null);

      setInitData(tg.initData);

      if (!!tg && !!tg.requestFullscreen) {
        tg.requestFullscreen();
        tg.expand();
      }
    }

  }, [tg.initDataUnsafe, tg.initData]);

  const onClose = () => {
    tg.close()
  }

  if (process.env.REACT_APP_DEV_MODE === 'true') {
    console.log(tg);
  }

  return {
    onClose,
    tg,
    user,
    auth,
    initData,
  }
}

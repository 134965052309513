import React, {useEffect, useState} from "react";
import {ROOT_URL} from "../../../../config/url";
import moment from "moment";
import {useProfile} from "../../../../hooks/useProfile";
import axios from "axios";

export const useHabitList = () => {
  const {profile, auth, initData} = useProfile();

  const [habits, setHabits] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    axios.get('/api/v1/habits/day', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        date: moment().format("YYYY-MM-DD")
      }
    }).then((response) => {
      setHabits(response.data.data);
    });
  }, [isUpdate]);

  const handleCheckboxChange = (habitUuid) => {
    // axios.put('/api/v1/habit/' + habitUuid + '/check', {}, {
    //   withCredentials: false, baseURL: ROOT_URL, headers: {
    //     'X-Telegram-Auth-Date': auth.auth_date,
    //     'X-Telegram-Hash': auth.hash,
    //     'X-Telegram-Init-Data': btoa(initData),
    //     'User': profile.uuid,
    //     'Content-Type': 'application/json'
    //   }, params: {
    //     date: moment().format("YYYY-MM-DD")
    //   }
    // }).then((response) => {
    //   setHabits((prevTasks) => [...prevTasks.map((habit) => {
    //     if (habit.uuid === habitUuid) {
    //       habit.checked_at = response.data.data.checked_at;
    //     }
    //     return habit;
    //   })]);
    // });
  };

  const editTask = (object) => {
    setHabits((prevTasks) => [...prevTasks.map((task) => {
      if (task.uuid === object.uuid) {
        task = object;
      }
      return task;
    })]);
  }

  return {
    profile,

    habits,

    isUpdate, setIsUpdate,

    handleCheckboxChange,
  }
}
